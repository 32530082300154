import React from "react";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "JNF";
  }, []);

  return (
    <div className="content">
      <section className="container">
        <div
          className="hero flex items-centre justify-between content"
          id="about"
        >
          <div className="left flex-1 justify-center">
            <img
              src={window.location.origin + "/Images/profile.png"}
              alt="Profile"
            ></img>
          </div>
          <div className="right flex-1">
            <h6>Natan Brault</h6>
            <h1>
              Dev<br></br> <span>Of All Trades</span>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Doloremque illum nam nobis, minima laudantium fugit sequi nostrum
              quod impedit, beatae necessitatibus praesentium optio labore nemo!
            </p>
            <div>
              <button className="btn btn-secondary">Télécharger mon CV</button>
            </div>
          </div>
        </div>
      </section>

      <div className="hero separate">
        <hr></hr>
      </div>

      <section id="project">
        <div className="hero flex items-centre justify-between">
          <div className="center flex-1 center-title">
            <h1>
              <span>Mes Projets</span>
            </h1>
          </div>
        </div>

        <div className="hero flex items-centre justify-between">
          <div className="center flex-1 justify-center">
            <a href="https://grafiklabor.cc/">
              {" "}
              <img src="Images/grafiklabor.png" alt="Grafik Labor"></img>{" "}
            </a>
          </div>
          <div className="right flex-1">
            <h1>
              <span>Grafik Labor</span>
            </h1>
            <p>
              Site utilisé pour la journée de conférence Grafik Labor portant
              sur le logiciel libre graphique
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>

        <div className="hero flex items-centre justify-between">
          <div className="center flex-1 justify-center">
            <a>
              {" "}
              <img src="Images/tracegps.png" alt="Trace GPS"></img>{" "}
            </a>
          </div>
          <div className="right flex-1">
            <h1>
              <span>Trace GPS</span>
            </h1>

            <p>
              Logiciel Windows et application mobile d'enregistrement et de
              partage de parcours effectués
            </p>
          </div>
        </div>
      </section>
      <hr></hr>
      <section id="contact">
        <div className="hero flex items-centre justify-between">
          <div className="center flex-1 center-title">
            <h1>
              <span>Contacts</span>
            </h1>
          </div>
        </div>
        <div className="hero flex items-centre justify-between">
          <div className="center flex-1 justify-center">
            <a></a>
          </div>
          <div className="right flex-1">
            <h1>
              <span>WIP</span>
            </h1>

            <p>This part is still in WIP</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
