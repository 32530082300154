import React from "react";

const Header = () => {
  return (
    <header id="headerId sticky">
      <div className="container">
        <nav className="flex items-centre justify-between">
          <div className="left flex justfiy-right">
            <div>
              <a href="/#about">Moi</a>
              <a href="/#project">Projets</a>
              <a href="/#contact">Contact</a>
              <a href="/login">WIP</a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
