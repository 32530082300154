import React from "react";

const Footer = () => {
  return (
    <footer>
      <hr></hr>
      <p>&copy; 2023 mon Portfolio</p>
    </footer>
  );
};

export default Footer;
