import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./components/style.css";
import "./components/utilities.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
import Header from "./header";
import Footer from "./footer";
import Login from "./login";
import Register from "./Register";
import App from "./App";
import JNF from "./jnf";

const Switch = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/jnf" element={<JNF />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Header />
    <Switch />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
