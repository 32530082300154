import { useEffect } from "react";
import React from "react";

// check user perms
// if user is not logged in, redirect to login page
// wait until this page is loaded to check user perms
/*
useEffect(() => {
  fetch("/api/CheckUser")
    .then((response) => response.json())
    .then((data) => {
      if (data === false) {
        window.location.href = "/login";
      }
    });
});
*/

function CheckUser() {
  fetch("/api/CheckUser")
    .then((response) => response.json())
    .then((data) => {
      if (data === false) {
        window.location.href = "/login";
      }
    });
}

const JNF = () => {
  return (
    <div onLoad={CheckUser()}>
      <p>Joue Numb Frer</p>
    </div>
  );
};

export default JNF;
