import React, { useState } from "react";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm) {
      fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data === true) {
            window.location.href = "/jnf";
            console.log("Login successful");
          } else {
            setErrorText("Login failed");
            console.log("Login failed");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    console.log(errorText);
  };

  const validateForm = () => {
    // username check
    if (username.length < 1) {
      setErrorText("Username is required");
      return false;
    }
    // password check
    if (password.length < 1) {
      setErrorText("Password is required");
      return false;
    }
    // success
    return true;
  };

  return (
    <div className="form-container">
      <h2 className="form-label">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-label">
          <label>
            Username:
            <input
              className="form-input"
              type="text"
              value={username}
              onChange={handleUsernameChange}
            />
          </label>
        </div>
        <div className="form-label">
          <label>
            Password:
            <input
              className="form-input"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
        </div>
        <div>
          <button type="submit" className="btn btn-secondary">
            Login
          </button>
        </div>
      </form>
      <br></br>
      <div>
        <p className="form-error-label">{errorText}</p>
      </div>
      <br></br>
      <div>
        <p className="form-label">
          Don't have an account? &nbsp;
          <a href="/register" className="btn btn-secondary">
            Register
          </a>
        </p>
      </div>
    </div>
  );
}

export default Login;
